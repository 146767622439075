:root {
    --biofarma-main-white: #FFFFFF;
    --biofarma-main-black: #414048;
    --biofarma-main-blue: #06AEC9;
    --biofarma-main-blue-darker: #42929D;
    --biofarma-main-blue-gray: #7B9AAF;
    --biofarma-main-orange: #F58732;
    --biofarma-main-light-blue: #E7F3FF;
    --biofarma-main-lighter-blue: #F2F9FF;
    --biofarma-main-light-blue-darker: #D5EAFB;
    --biofarma-main-red: #F82E2C;
    --biofarma-bg-warning: #FEFFEE;
}