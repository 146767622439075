@font-face {
    font-family: 'icomoon';
    src: url('/assets/fonts/icomoon/icomoon.eot?84dcbh');
    src: url('/assets/fonts/icomoon/icomoon.eot?84dcbh#iefix') format('embedded-opentype'), 
    url('/assets/fonts/icomoon/icomoon.ttf?84dcbh') format('truetype'), 
    url('/assets/fonts/icomoon/icomoon.woff?84dcbh') format('woff'), 
    url('/assets/fonts/icomoon/icomoon.svg?84dcbh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-instagram:before {
    content: "\e900";
    background: -webkit-linear-gradient(300deg,#F58529,#FEDA77,#DD2A7B,#8134AF,#515BD4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.icon-youtube:before {
    content: "\e901";
    color: #f00;
}
.icon-twitter:before {
    content: "\e902";
    color: #58befe;
}
.icon-facebook:before {
    content: "\e903";
    color: #306cc5;
}
.icon-store:before {
    content: "\e904";
    color: #f58732;
}
.icon-register:before {
    content: "\e905";
    color: #f58732;
}
.icon-shop:before {
    content: "\e906";
    color: #f58732;
}
.icon-cart:before {
    content: "\e907";
}
.icon-trash:before {
    content: "\e908";
    color: #7b9aaf;
}
.icon-requisition:before {
    content: "\e909";
    color: #7b9aaf;
}
.icon-wishlisted:before {
    content: "\e90a";
    color: #f58732;
}
.icon-wishlist:before {
    content: "\e90b";
    color: #7b9aaf;
}
.icon-print:before {
    content: "\e90c";
    color: #7b9aaf;
}
.icon-share:before {
    content: "\e90e";
}
.icon-web:before {
    content: "\e90d";
    color: #7b9aaf;
}
.icon-hotline:before {
    content: "\e90f";
    color: #7b9aaf;
}
.icon-email:before {
    content: "\e910";
    color: #7b9aaf;
}
.icon-pencil:before {
    content: "\e963";     
    color: #7b9aaf;
}
.icon-arrow:before {
    content: "\e967";     
    color: #fff;
}
.icon-distributor-o:before {
    content: "\e970";
    color: #42929d;
}
.icon-chat:before {
    content: "\e968";
    color: #f58732;
}
.icon-ar:before {
    content: "\e96f";
    color: #f58732;
}