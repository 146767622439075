.category-heading {
    margin-top: 30px;
    color: #414048;
    letter-spacing: 0;
}
.category-landing .subtitle-heading span {
    font-size: 18px !important;
    color: #414048;
    letter-spacing: 0;
}
.category-landing .pagebuilder-column.custom-column {
    margin: 10px;
    justify-content: center !important;
}
.category-landing .pagebuilder-column h1 {
    font-weight: 600;
    font-size: 18px !important;
    margin-left: 0px !important;
    text-align: left !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
    height:100%;
}

.category-landing .pagebuilder-column-group .pagebuilder-column {
    flex-direction: row !important;
    background: #FFFFFF !important;
    box-shadow: 0 0 4px rgba(0,0,0,.2);
    border-radius: 8px !important;
    height: auto !important;
    min-height: 100px;
    align-items: center;
    justify-content: flex-start !important;
    padding-left: 20px;
}

.category-landing .pagebuilder-column-group .pagebuilder-column figure a {
    display: block;
    width: 55px;
    height: 55px;
}

.category-landing .pagebuilder-column-group .pagebuilder-column figure a img {
    height: 100% !important;
    width: 100% !important;
    object-fit: contain !important;
}

/**
 responsive mobile mode 
*/
@media (max-width: 767px){
    .category-landing {
        margin-bottom: 80px;
    }
    .category-heading {
        font-size: 30px;
    }
    .category-landing .subtitle-heading span {
        font-size: 14px !important;
    }
    .category-landing .pagebuilder-column-group {
        flex-wrap: wrap;
    } 
    .category-landing .pagebuilder-column.custom-column {
        background-attachment: scroll !important;
        flex-basis: 100%;
        max-width: 100%;
        height: 200px;
    }
    .category-landing .pagebuilder-column-group .pagebuilder-column {
       margin: 5px !important;
    }
    #maincontent .category-landing .pagebuilder-column h1 {
        font-size: 18px !important;
        margin: 0 0 0 0px !important;
    }
}

/**
 responsive desktop mode
*/
@media (min-width: 768px){
    .category-heading {
        font-size: 50px;
    }
    .category-landing .pagebuilder-column.custom-column {
        height: 375px;
    } 
}