.tooltip-container {
    max-width: 400px !important;
    min-width: 350px !important;
    border-radius: 15px !important;
    
}
.tooltip-container .introjs-disabled {
    display: none;
}
.tooltip-container.introjs-floating {
    position: fixed;
}

.tooltip-container .introjs-nextbutton,.introjs-nextbutton:hover {
    background-color: #f58732 !important;
    border-radius: 22px;
    color: #FFFFFF !important;
    font-size: 14px;
    text-shadow: none !important;
}

.tooltip-container .introjs-nextbutton:focus {
    border: none;
    box-shadow: none;
}

.tooltip-container .introjs-prevbutton,.introjs-prevbutton:hover,.introjs-prevbutton:focus {
    border: 1px solid #f58732 !important;
    border-radius: 22px;
    background-color: #FFFFFF !important;
    color: #f58732 !important;
    font-size: 14px;
    text-shadow: none !important;
    box-shadow: none !important;
}

.tooltip-container .introjs-tooltiptext {
    text-align: center;
    padding: 20px 30px 0;
    line-height: normal;
}

.tooltip-container .introjs-tooltiptext h2 {
    color: #42929d;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 1;
    margin-bottom: 15px;
    width: 100%;
}
.tooltip-container .introjs-tooltiptext p {
    color: #414048;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 1.2;
    font: normal normal normal 14px/17px Roboto;
    max-width: 280px;
    margin: auto;
}

.tooltip-container .introjs-bullets ul li a.active {
    background-color: #7B9AAF;
}

.tooltip-container .introjs-skipbutton {
    color: transparent !important;
    width: 50px;
    height: 50px;
}

.tooltip-container .introjs-skipbutton::after{
    content: '';
    display: block;
    background-image: url('/assets/img/close-icon-introjs.svg');
    width: 22px;
    height: 22px;
    position: absolute;
    top: 0px;
    left: 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 15px;
}

.introjs-arrow.top{
    border-bottom-color: transparent !important;
}

body.no-scroll{
    overflow: hidden;
}

.tooltip-container .introjs-tooltipbuttons {
    border-top: 0;
    padding: 0 20px 20px;
}

.tooltip-container .introjs-arrow {
	display: none !important;
}

.introjs-helperLayer {
	box-shadow: rgba(33,33,33,.0) 0px 0px 1px 2px, rgba(33,33,33,.5) 0px 0px 0px 10000px !important;
}
.introjs-helperLayer.bg-white{
    background: #FFFFFF;
}

@media only screen and (min-width: 768px) {
    .tooltip-container.introjs-floating {
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .tooltip-container {
        min-width: 260px !important;
    }
    .tooltip-container .introjs-tooltiptext {
        padding: 20px 20px 0;
    }
}